<template>
  <div>
    <div v-for="ele in configArray" :key="ele" class="main-wrapper">
      <App
        v-if="Object.keys(ele?.config?.design_config).length"
        :functionalConfigAddToCart="ele?.config?.functional_config"
        :config="ele?.config?.design_config"
        :pageDetail="pageDetail"
        :recomm_slug="recomm_slug"
        :configId="ele?.id"
        :id="id"
        :isEventWrapper="false"
      />
    </div>
  </div>
</template>

<script>
import App from '../App.vue';
import { Utility } from 'fdk-client-javascript/common';
import axios from 'axios';
export default {
  name: 'api-wrapper',
  props: {
    id: String
  },
  data() {
    return {
      functionalConfigAddToCart: Object,
      config: Object,
      pageDetail: undefined,
      recomm_slug: undefined,
      configArray: []
    };
  },
  components: {
    App
  },
  async mounted() {
    let url = window.location.href;
    this.pageDetail = Utility.convertUrlToAction(url);
    this.recomm_slug = this.id;
    await this.getData(this.recomm_slug, this.pageDetail);
  },
  methods: {
    getData(recomm_slug, pageDetail) {
      const that = this;
      const url = window.location.origin;
      axios
        .get(
          `${url}/ext/recalpha/api/application/v1.0/recommend/${recomm_slug}/pages/${pageDetail.page.type}/config`
        )
        .then((response) => {
          that.configArray = response?.data;
          that.config = response.data?.config?.design_config;
          that.functionalConfigAddToCart = response.data?.config?.functional_config;
        })
        .catch((error) => {
          console.error('Fetch Config Error ::: ', error);
        });
    }
  }
};
</script>

<style lang="less" scoped>
.main-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  gap: 1rem;
}
</style>
