<template>
  <div>
    <App id="price" />
  </div>
</template>

<script>
import App from "./hoc/ApiWrapper.vue";
export default {
  name: "by-brand",
  components: {
    App,
  },
};
</script>
