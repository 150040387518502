<template>
  <App id="recently-launched" />
</template>

<script>
import App from './hoc/ApiWrapper.vue';
export default {
  name: 'by-brand',
  components: {
    App
  }
};
</script>
