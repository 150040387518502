<template>
  <div class="icon-title">
    <!-- <div class="brand-icon" v-if="iconUrl != ''">
      <img alt="icon" :src="iconUrl" />
    </div> -->
    <div class="full-width">
      <div class="header-title" v-if="title.length">
        {{ getTitle }}
      </div>
      <div class="header-subtitle" v-if="subTitle.length">
        {{ subTitle }}
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'IconAndTitle',
  props: {
    iconUrl: {
      type: String,
      default: ''
    },
    brand: {
      type: String,
      default: 'brand'
    },
    gender: {
      type: String,
      default: 'gender'
    },
    category: {
      type: String,
      default: 'category'
    },
    title: {
      type: String,
      default: ''
    },
    subTitle: {
      type: String,
      default: ''
    }
  },
  // data() {
  //   return { defaultTitle: "" };
  // },
  mounted() {
    // this.defaultTitle = this.title;
  },
  computed: {
    getTitle() {
      let defaultTitle = this.title;
      if (this.title) {
        defaultTitle = defaultTitle.replace('{gender}', this.gender);
        defaultTitle = defaultTitle.replace('{brand}', this.brand);
        defaultTitle = defaultTitle.replace('{category}', this.category);
      }
      return defaultTitle;
    }
  }
};
</script>

<style scoped>
.icon-title {
  display: flex;
  margin-left: 30px;
  overflow: auto;
}
.brand-icon {
  margin-right: 8px;
  margin-bottom: 10px;
}
.brand-icon img {
  height: 50px;
}
.header-title {
  font-weight: var(--titleFontWeight);
  font-size: var(--titleFontSize);
  color: var(--titleColor);
  text-align: var(--titleAlignment);
  margin-bottom: 8px;
  /* font-family: var(--titleFontFamily) */
  /* font-family: var(--titleFontFamily); Font Family should be as per the Sales Channel  */
}
.header-subtitle {
  opacity: 0.4;
  font-weight: var(--subtitleFontWeight);
  font-size: var(--subtitleFontSize);
  color: var(--subtitleColor);
  text-align: var(--subtitleAlignment);
  margin-bottom: 18px;
}
.full-width {
  width: 100%;
  margin-bottom: var(--subTitleBottomSpacing);
}
@media (max-width: 768px) {
  .icon-title {
    margin-left: auto;
  }
}
</style>
