<template>
  <div>
    <App id="trending" /> 
  </div>
</template>

<script>
import App from './hoc/ApiWrapper.vue';
export default {
  name: 'by-trending-products', // why all components have same name?
  components: {
    App
  }
};
</script>
